<template>
  <div class="ecommerce-application">
    <b-modal
      id="detailModal"
      centered
      hide-footer
      size="xl"
      title="Ürün detayları"
    >
      <div class="ecommerce-application">
        <section class="grid-view">
          <b-card
            v-for="product in productsAll"
            :key="product.id"
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center pt-0">
              <b-img
                :alt="`${product.name}-${product.id}`"
                fluid
                class="card-img-top"
                :src="product.photo.thumb_url"
              />
            </div>

            <!-- Product Details -->
            <b-card-body>
              <div class="item-wrapper">
                <!-- <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div> -->
                <div>
                  <h6 class="item-price">
                    ₺{{ product.list_price }}
                  </h6>
                </div>
              </div>
              <h6 class="item-name">
                <span
                  class="text-body"
                >
                  {{ product.name }}
                </span>
                <b-card-text class="item-company">
                  Ürün Kodu:
                  {{ product.code }}
                </b-card-text>
              </h6>
              <b-card-text class="item-description">
                <!-- {{ product.description }} -->
                <!-- Vergi Oranı: {{ product.vat_rate }}<br>
                Stok Miktarı: {{ Number(product.stock_count).toFixed(0) }}<br> -->
                Ürün Birimi: {{ product.unit }}
                <br>
                Stok kodu: {{ product.code }}
                <br>
                Adet: {{ product.details.attributes.quantity }}
              </b-card-text>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <div class="item-wrapper">
                <div class="item-cost">
                  <h4 class="item-price">
                    ₺{{ product.list_price }}
                  </h4>
                </div>
              </div>
            </div>
          </b-card>
        </section>
      </div>
    </b-modal>
  </div>

</template>
<script>
import {
  BModal, VBModal, BCard, BCardText, BCardBody, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BCard, BCardText, BCardBody, BImg,
  },
  directives: { VBModal },
  props: {
    productsAll: {
      type: Array,
      required: true,
    },
  },
  // watch: {
  //   productsAll(to) {
  //     console.log(to)
  //   },
  // },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce";
</style>
