<template>
  <!-- table -->
  <div class="ecommerce-application">
    <product-detail :products-all="selectedRowProduct" />
    <vue-good-table
      :columns="columns"
      :rows="invoices"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'created_at'">
          {{ new Date(props.row.created_at).toLocaleDateString() }}
        </span>
        <b-button
          v-else-if="props.column.field === 'sharing_preview_url'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-info"
          target="_blank"
          :href="props.row.sharing_preview_url"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">Fatura Görüntüle</span>
        </b-button>
        <b-button
          v-else-if="props.column.field === 'product_detail'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-info"
          @click="
            (selectedRowProduct = props.row.products),
            $bvModal.show('detailModal')
          "
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">Ürün Detayları</span>
        </b-button>
        <b-button
          v-else-if="props.column.field === 'order_detail'"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-info"
          @click="getDetail(props.row.id)"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">Görüntüle</span>
        </b-button>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-input
              v-model="pageLength"
              class="mx-1"
              type="number"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import { BPagination, BFormInput, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProductDetail from './ProductDetail.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormInput,
    BButton,
    ProductDetail,
  },
  directives: { Ripple },
  data() {
    return {
      invoices: [],
      selectedRowProduct: [],
      searchTerm: '',
      pageLength: 15,
      columns: [
        {
          label: 'Müşteri İsmi',
          field: 'customerName',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Oluşturulma Tarihi',
          field: 'created_at',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Toplam',
          field: 'net_total',
          width: '120px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Fatura',
          field: 'sharing_preview_url',
          width: '120px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Ürün Detayları',
          field: 'product_detail',
          width: '120px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Sipariş Durumu',
          field: 'order_detail',
          width: '120px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
      ],
    }
  },
  watch: {
    selectedRowProduct(to) {
      // console.log(to)
      to.forEach((element, i) => {
        this.invoices.forEach(el => {
          const dtl = el.details.find(x => {
            if (x.relationships.product.data) {
              // console.log(x.relationships.product.data.id, element.id)
              return x.relationships.product.data.id === element.id
            }
            return ''
          })
          if (dtl) {
            this.selectedRowProduct[i].details = dtl
          }
        })
      })
      // console.log(this.selectedRowProduct)
    },
  },
  created() {
    this.getInvoices()
  },
  methods: {
    async getInvoices() {
      await this.$http
        .get(
          `invoice?role=${this.$store.state.auth.userInfo.role}&userId=${this.$store.state.auth.userInfo.user.displayName}`,
        )
        .then(async res => {
          await res.data.data.forEach(async (element, i) => {
            this.invoices.push({
              created_at: element.data.attributes.created_at,
              sharing_preview_url: element.data.attributes.sharing_preview_url,
              net_total: element.data.attributes.net_total,
              products: [],
              id: element.data.id,
              details: [],
            })
            await element.included.forEach(el => {
              if (el.type === 'sales_invoice_details') {
                this.invoices[i].details.push(el)
              } else if (el.type === 'products') {
                this.invoices[i].products.push({ ...el.attributes, id: el.id })
              } else if (el.type === 'contacts') {
                this.invoices[i].customerName = el.attributes.name
              }
            })
          })
        })
    },
    async getDetail(id) {
      await this.$http.get(`/invoice/order/detail?id=${id}`).then(res => {
        this.$swal({
          title: `Sipariş ${res.data.data.attributes.item_type} aşamasında. İlerleme durumunda tarafınıza bilgi aktarılacaktır`,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }).catch(err => {
        if (err.response.status === 404) {
          this.$swal({
            title: 'Fatura kaydı bulunamadı',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (err.response.status === 429) {
          this.$swal({
            title: 'Kısa süre sonra tekrar deneyin.',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Bilinmeyen bir hata Oluştu',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce";
</style>
